<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-card-title primary-title>Add Lead </v-card-title>
      <v-card-text>
        <v-form v-model="validForm">
          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="5">
                <label class="font-weight-medium" for="name">
                  First Name: <span class="red--text">*</span></label
                >
                <v-text-field
                  outlined
                  class="rounded"
                  dense
                  :rules="[rules.required]"
                  v-model="newlead.name"
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="5" class="px-1">
                <label class="font-weight-medium" for="lastname">
                  Last Name: <span class="red--text">*</span>
                </label>
                <v-text-field
                  class="rounded"
                  dense
                  :rules="[rules.required]"
                  v-model="newlead.last_name"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="2">
                <label class="font-weight-medium" for="gender">Gender: </label>
                <v-select
                  :items="genders"
                  item-text="label"
                  item-value="value"
                  v-model="newlead.isMale"
                  outlined
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="texts1" no-gutters>
              <v-col cols="12" sm="3">
                <label class="font-weight-medium" for="dob"
                  >Date of Birth:
                </label>
                <ma-date-picker
                  v-model="newlead.birthDate"
                  past
                  :editable="true"
              /></v-col>
              <!--  <v-col cols="12" sm="3">
              <v-text-field
                v-model="newlead.weight"
                label="Weight (lb)"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="3"
              ><v-text-field
                v-model="heightfeet"
                label="Height (Ft)"
                outlined
                dense
            /></v-col>
            <v-col cols="12" sm="3"
              ><v-text-field
                v-model="heightinch"
                label="Height (In)"
                outlined
                dense
            /></v-col> -->
              <v-col cols="12" sm="3" class="px-1">
                <label class="font-weight-medium" for="phone">
                  Phone: <span class="red--text">*</span></label
                >
                <v-text-field
                  class="rounded"
                  prepend-inner-icon="mdi-numeric-positive-1"
                  autocomplete="tel"
                  dense
                  :rules="[rules.phone]"
                  v-model="phonelead"
                  outlined
                ></v-text-field
              ></v-col>
              <v-col cols="12" sm="6">
                <label class="font-weight-medium" for="email"> Email: </label>
                <v-text-field
                  class="rounded"
                  dense
                  v-model="maillead"
                  outlined
                ></v-text-field
              ></v-col>
            </v-row>

            <v-row class="texts1" no-gutters>
              <v-col cols="12" sm="4">
                <label class="font-weight-medium" for="source">
                  Source: <span class="red--text">*</span>
                </label>
                <v-autocomplete
                  v-model="newlead.sourceId"
                  :loading="loadingRefer"
                  :items="references"
                  :rules="[rules.required]"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
              /></v-col>
              <template v-if="admins">
                <v-col cols="12" sm="4" class="px-2">
                  <label class="font-weight-medium" for="agent"> Agent: </label>
                  <v-autocomplete
                    v-model="newlead.agentUuid"
                    :items="workers"
                    item-text="fullname"
                    item-value="uuid"
                    outlined
                    hide-details
                    dense
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.fullname"
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-if="data.item.ocupation"
                          class="text-capitalize"
                          v-html="data.item.ocupation.name"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </template>
              <v-col cols="12" sm="4" class="px-1">
                <label class="font-weight-medium" for="metal">Tag: </label>
                <v-select
                  :items="tags"
                  item-text="name"
                  item-value="id"
                  v-model="newlead.tagId"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <!--    <v-col cols="12" sm="4" class="px-1">
                <label class="font-weight-medium" for="metal"
                  >Metal Plan:
                </label>
                <v-select
                  :items="metalplan"
                  item-text="label"
                  item-value="value"
                  v-model="newlead.policyPlan"
                  outlined
                  dense
                ></v-select>
              </v-col> -->

              <!-- <v-col cols="12" sm="4">
                <label class="font-weight-medium" for="members">
                  Members:
                </label>
                <v-text-field
                  v-model="newlead.members"
                  outlined
                  type="Number"
                  dense
                ></v-text-field>
              </v-col> -->
            </v-row>
            <!--     <v-row no-gutters class="texts1"> -->
            <!-- <v-col cols="12" sm="6" class="pr-1">
                <label class="font-weight-medium" for="company">
                  Company:
                </label>
                <v-autocomplete
                  v-model="newlead.companyId"
                  :loading="loadingRefer"
                  :items="companies"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
              /></v-col> -->

            <!--   </v-row> -->
            <!--   <v-divider class="mb-1 texts2 mx-2"></v-divider>
          <div class="d-flex justify-center text-center">
            <h4>Medical History</h4>
          </div>
          <v-divider class="my-1 mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-select
                  class="mr-1"
                  :items="doctors"
                  v-model="newlead.medicalHistory.doctorChoiceUuid"
                  item-text="fullname"
                  item-value="uuid"
                  outlined
                  dense
                  label="Doctor of Choice"
                ></v-select></div
            ></v-col>
            <v-col cols="12" sm="6">
              <div class="d-flex">
                <v-select
                  class="mr-1"
                  :items="types"
                  v-model="newlead.procedureInterest"
                  item-text="name"
                  item-value="name"
                  outlined
                  dense
                  label="Procedure(s) of Interest"
                ></v-select></div
            ></v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.medicalCondition"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Medical Condition"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.alergies"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Alergies"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1">
            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.previosSurgery"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Previous surgeries"
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.currentMedications"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Current medications"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-divider class="my-1 texts mx-2"></v-divider>
          <v-row class="mx-1 texts">
            <v-col cols="12" sm="4">
              <v-switch
                v-model="newlead.medicalHistory.smoking"
                inset
                label="Smoking"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="newlead.medicalHistory.alcohol"
                inset
                label="Alcohol"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="othersubstances"
                inset
                label="Other Substances"
              ></v-switch>
            </v-col>
          </v-row> -->
            <!--   <template v-if="othersubstances">
            <v-row class="mx-1 texts2">
              <v-col cols="12" sm="12">
                <v-textarea
                  auto-grow
                  v-model="newlead.medicalHistory.otherSubstances"
                  rows="1"
                  row-height="65"
                  outlined
                  dense
                  label="Details"
                ></v-textarea>
              </v-col>
            </v-row>
          </template> -->
            <!--     <v-row class="mx-1 texts2">
            <v-col cols="12" sm="12">
              <v-textarea
                auto-grow
                v-model="newlead.medicalHistory.details"
                rows="1"
                row-height="65"
                outlined
                dense
                label="Notes"
              ></v-textarea>
            </v-col>
          </v-row> -->
          </v-container>
        </v-form>
        <v-alert
          dense
          class="actions mt-2"
          type="error"
          :value="uuidexist != null || !validEmail || !validForm || !validDate"
        >
          {{
            !validEmail
              ? "Invalid email"
              : !validDate
              ? "Invalid Date of Birth"
              : !validForm
              ? "All fields with ( * ) are required"
              : `This ${getpatientN} already exists`
          }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="cancelDialog"> Cancel </v-btn>
        <template v-if="uuidexist != null">
          <v-btn color="green darken-1" text @click="gotoPatient">
            {{ "View " + getpatientN }}
          </v-btn>
        </template>
        <v-btn
          :disabled="
            !Createvalid ||
            uuidexist != null ||
            loadingAcciones ||
            !validEmail ||
            !validForm ||
            !validDate
          "
          :loading="loadingAcciones"
          color="green darken-1"
          text
          @click="addLead"
        >
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { notifyError, notifySuccess } from "@/components/Notification";
import rules from "@/components/account/rules";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
import { getAPI } from "@/api/axios-base";
import moment from "moment";
import { isSupervisor } from "@/store/getters";
import { cleanPhoneNumber } from "@/utils/formatPhone";
export default {
  name: "add-lead",
  components: { MaDatePicker },
  data() {
    return {
      loadingAcciones: false,
      validForm: false,
      rules: {
        email: rules.email,
        required: rules.required,
        nozero: rules.noZero,
        phone: rules.isPhone,
        maxCharsfull: (v) => v.length >= 3 || this.$t("min3Chars"),
      },
      types: [],

      phonelead: "",
      maillead: "",
      uuidexist: null,
      admins: false,
      newlead: {
        sourceId: null,
        name: "",
        last_name: "",
        phone: "",
        email: "",
        tagId: null,
        isMale: false,
        birthDate: "",
        agentUuid: "",
      },

      genders: [
        { label: "Female", value: false },
        { label: "Male", value: true },
      ],
      metalplan: [
        { label: "Bronze", value: "BRONZE" },
        { label: "Silver", value: "SILVER" },
        { label: "Gold", value: "GOLD" },
      ],
    };
  },
  watch: {
    phonelead: {
      async handler() {
        this.phonelead = cleanPhoneNumber(this.phonelead);
        if (this.phonelead[0] == "1") {
          this.phonelead = this.phonelead.slice(1);
        }
        this.newlead.phone = this.phonelead;
        if (this.phonelead.length == 10) {
          await this.actFilterPatient({
            query: this.phonelead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else if (this.phonelead.length == 0) {
          this.uuidexist = null;
        }
      },
    },
    maillead: {
      async handler() {
        this.newlead.email = this.maillead;
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (pattern.test(this.maillead)) {
          await this.actFilterPatient({
            query: this.maillead,
            range: { limit: 100, offset: 0 },
          });
          if (this.patients.length != 0) {
            this.uuidexist = this.patients[0].uuid;
          } else {
            this.uuidexist = null;
          }
        } else {
          this.uuidexist = null;
        }
      },
    },
  },
  computed: {
    ...mapState(["configForm"]),
    ...mapState("crmSettingsModule", [
      "loadingRefer",
      "references",
      "companies",
      "tags",
    ]),
    ...mapState("crmEmployeeModule", ["workers", "loading"]),
    ...mapGetters([
      "getCoordinatorN",
      "getpatientN",
      "isAdmin",
      "isSuper",
      "isSupervisor",
      "getUuid",
    ]),
    ...mapState("crmMedicFormModule", ["patients"]),
    validDate() {
      if (this.newlead.birthDate == "" || this.newlead.birthDate == null) {
        return true;
      }
      const d = moment(this.newlead.birthDate);
      const now = moment();
      if (d.isBefore(now)) {
        return true;
      }
      return false;
    },
    validEmail() {
      if (this.newlead.email == null || this.newlead.email == "") {
        return true;
      }
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (pattern.test(this.newlead.email)) {
        return true;
      }
      return false;
    },
    Createvalid() {
      if (
        this.newlead.name != "" &&
        this.newlead.last_name != "" &&
        this.newlead.phone != "" &&
        this.newlead.phone.length == 10 &&
        this.newlead.sourceId != null
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actFilterPatient"]),
    ...mapActions("crmSettingsModule", [
      "actListReferences",
      "actListCompaniesNom",
      "actListTags",
    ]),
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    cancelDialog() {
      this.$router.push("/leads_center");
    },
    gotoPatient() {
      this.$router.push(`/clients/details/${this.uuidexist}`);
    },
    addLead() {
      this.loadingAcciones = true;
      let body = Object.assign({}, this.newlead);
      body.phone = "+1" + body.phone;

      if (body.birthDate) {
        body.birthDate = moment(body.birthDate).utc().toISOString();
      }
      if (!this.admins) {
        body.agentUuid = this.getUuid;
      }
      if (body.tagId) {
        body.tagId = Number(body.tagId);
      }

      body = this.cleanNull(body);

      getAPI
        .post("/social/createLead/", body)
        .then(() => {
          this.cancelDialog();
          notifySuccess(`The Lead was added successfully`);
        })
        .catch((error) => {
          this.loadingAcciones = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
    cleanNull(obj) {
      for (var propName in obj) {
        if (
          obj[propName] === null ||
          obj[propName] === undefined ||
          obj[propName] === ""
        ) {
          delete obj[propName];
        }
      }
      return obj;
    },
  },
  async mounted() {
    this.actListReferences();
    this.actListTags();
    this.actListCompaniesNom();
    if (this.isAdmin || this.isSuper || this.isSupervisor) {
      this.admins = true;
      await this.actGetEmployees();
    }

    localStorage.setItem("leadcenter", true);
  },
};
</script>
<style lang="scss" scoped>
.texts1 {
  margin-top: -10px !important;
}
</style>
